import { filter, sortBy, uniqBy } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    of,
    throwError
} from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { getListOfCountryISOCodes } from '@mhp/aml-shared/country-mapping/country-mapping';
import { DealerInformation } from '@mhp/aml-shared/data-proxy/dealer-information.interface';
import { Dealer } from '@mhp/aml-shared/data-proxy/dealer.interface';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';
import { I18nService } from '@mhp/ui-shared-services';

export interface ExtendedDealer extends Dealer {
    knownAsTranslated: string;
}

export interface DealerInfoConfig {
    dataProxyBaseUrl: string;
    isDealerBuild: boolean;
}

export const DEALER_INFO_CONFIG_TOKEN = new InjectionToken<DealerInfoConfig>(
    'DealerInfoConfig'
);

/**
 * Provide info about available dealers.
 */
@Injectable({
    providedIn: 'root'
})
export class DealerInfoService {
    private readonly activeDealerInfoInvalidationSubject =
        new BehaviorSubject<void>(undefined);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly i18nService: I18nService,
        @Inject(DEALER_INFO_CONFIG_TOKEN)
        private readonly config: DealerInfoConfig
    ) {}

    /**
     * Invalidate cached information for #getActiveDealerInfo$()
     */
    invalidateActiveDealerInfo() {
        this.activeDealerInfoInvalidationSubject.next();
    }

    /**
     * Get information about the currently logged-in dealer.
     * Call will only return valid data in case it's called in context
     * of an authenticated dealer (dealer-token added to request)
     * Emits void in case a 404 response is received or the request is performed in a non-dealer-context,
     * otherwise rethrows error.
     */
    @MemoizeObservable()
    getActiveDealerInfo$(): Observable<DealerInformation | undefined> {
        // invalidate-able dealer-information obtained from endpoint
        const dealerInformationFromEndpoint$ =
            this.activeDealerInfoInvalidationSubject.pipe(
                switchMap(() =>
                    this.httpClient.get<DealerInformation>(
                        `${this.config.dataProxyBaseUrl}/api/dealer/information`
                    )
                ),
                shareReplay(1),
                catchError((error) => {
                    if (
                        error instanceof HttpErrorResponse &&
                        error.status === 404
                    ) {
                        return of(undefined);
                    }
                    return throwError(error);
                })
            );

        return this.config.isDealerBuild
            ? dealerInformationFromEndpoint$
            : of(undefined);
    }

    /**
     * Get a list of dealers optionally filtered by the given template
     * @param filterTemplate An optional template to filter entries.
     */
    getDealerList$(
        filterTemplate?: Partial<ExtendedDealer>
    ): Observable<ExtendedDealer[]> {
        return this.getDealerListInternal$().pipe(
            map((dealerList) => {
                if (!filterTemplate) {
                    return [...dealerList];
                }
                return filter(dealerList, filterTemplate);
            })
        );
    }

    /**
     * Get a list of dealers that are relevant for display (dealerTypeCode: 'CFRNCH'), optionally
     * filtered by the given filter-template, sorted by the translated dealer-name, asc.
     * @param filterTemplate
     */
    getDealersRelevantForDisplay$(filterTemplate?: Partial<ExtendedDealer>) {
        return this.getDealerList$({
            ...filterTemplate,
            dealerType: 'CFRNCH'
        }).pipe(map((dealers) => sortBy(dealers, 'knownAsTranslated')));
    }

    /**
     * Get a list of dealers that are relevant for display (dealerTypeCode: 'CFRNCH') for the ISO2 country emitted by the given targetCountry$ stream, optionally
     * filtered by the given filter-template, sorted by the translated dealer-name, asc.
     * @param targetCountry$ The target country to filter dealers for.
     * @param filterTemplate
     */
    getDealersRelevantForDisplayForCountry$(
        targetCountry$: Observable<string | undefined>,
        filterTemplate?: Partial<ExtendedDealer>
    ): Observable<ExtendedDealer[] | undefined> {
        return targetCountry$.pipe(
            switchMap((activeCountry) => {
                if (!activeCountry) {
                    return of(undefined);
                }
                if (activeCountry.length !== 2) {
                    throw new IllegalStateError(
                        'Country must be of length 2',
                        activeCountry
                    );
                }
                return this.getDealersRelevantForDisplay$({
                    ...filterTemplate,
                    billingCountryCode: activeCountry
                });
            })
        );
    }

    /**
     * Get a stream emitting a list of country-infos for countries in which
     * dealers are available.
     */
    @MemoizeObservable()
    getCountriesWhereDealersAreAvailable$(): Observable<
        { countryISO: string; label: string }[]
    > {
        return this.i18nService.getActiveLang$().pipe(
            map(() => {
                const countriesWithLabels = getListOfCountryISOCodes().map(
                    (countryISO) => ({
                        countryISO,
                        label: translate<string>(`COMMON.REGION.${countryISO}`)
                    })
                );
                return countriesWithLabels?.sort((regionA, regionB) =>
                    regionA.label.localeCompare(regionB.label)
                );
            }),
            switchMap((countries) =>
                this.getDealersRelevantForDisplay$().pipe(
                    map((allDealers) => {
                        const countriesWithDealers = allDealers.reduce(
                            (countriesWithDealer, currentDealer) => {
                                countriesWithDealer.add(
                                    currentDealer.billingCountryCode
                                );
                                return countriesWithDealer;
                            },
                            new Set<string>()
                        );

                        return countries.filter((currentCountry) =>
                            countriesWithDealers.has(currentCountry.countryISO)
                        );
                    })
                )
            )
        );
    }

    @MemoizeObservable()
    private getDealerListInternal$(): Observable<ExtendedDealer[]> {
        return combineLatest([
            this.httpClient.get<{ dealer: Dealer }[]>(
                `${this.config.dataProxyBaseUrl}/api/dealer/findAll`
            ),
            this.i18nService.getActiveLang$()
        ]).pipe(
            map(([dealers]) =>
                dealers.map((dealerEntry) => dealerEntry.dealer)
            ),
            map((dealers) => {
                let frozenDealers = dealers.map((dealerInfo) => {
                    const knownAsTranslated =
                        this.i18nService.translateWithFallback(
                            `DEALERS.NAMES.${dealerInfo.dealerCode}`,
                            dealerInfo.name
                        );

                    const dealerInfoWithTranslation = {
                        ...dealerInfo,
                        knownAsTranslated
                    };

                    return Object.freeze(dealerInfoWithTranslation);
                });

                frozenDealers = uniqBy(frozenDealers, 'dealerCode');

                return frozenDealers;
            }),
            shareReplay()
        );
    }
}
