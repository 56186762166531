<ng-container *transloco="let t">
    <mhp-header-bar>
        <ng-container header-bar_area-left>
            <div
                class="order-management__search-search-bar"
                [ngClass]="{
                    'order-management__search-search-bar':
                        (isSearchInputActive$() | async) === false
                }"
            >
                <mhp-search
                    [searchState]="searchState"
                    (intentSearch)="intentTriggerSearch()"
                ></mhp-search>
            </div>
            <div
                *ngIf="isDisplayingSearchResults$() | async"
                class="order-management__search-search-term"
            >
                <mhp-search-term
                    [searchTerm]="getActiveSearchTerm$() | async"
                    (clearSearch)="intentExitSearch()"
                >
                </mhp-search-term>
            </div>
        </ng-container>
        <ng-container header-bar_area-right>
            <mhp-ui-icon-button
                class="order-management__table-list-menu-right"
                [matMenuTriggerFor]="beforeMenu"
                svgIcon="mhp-ui:menu"
            ></mhp-ui-icon-button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="navigateToMainPage()">
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:back_2"
                    ></mhp-ui-icon-button>
                    {{ t('ORDER_MANAGEMENT.NAVIGATION.MODEL_SELECT') }}
                </button>
            </mat-menu>
        </ng-container>
    </mhp-header-bar>
    <div class="order-management__content-list">
        <h3>{{ t('ORDER_MANAGEMENT.OVERVIEW.TAB_2') }}</h3>
        <div class="order-overview__list-container">
            <div class="order-overview__list-header">
                <table
                    matSort
                    matSortActive="date"
                    matSortDirection="asc"
                    matSortDisableClear
                    (matSortChange)="sortData($event)"
                    class="aml-form__form order-management__table-list-table"
                >
                    <tr
                        class="aml-form__form order-management__table-list-header"
                    >
                        <th
                            mat-header-cell
                            class="order-management__table-list-thumbnail"
                        ></th>

                        <th
                            mat-header-cell
                            mat-sort-header="model"
                            class="order-management__table-list-model-title-left"
                        >
                            {{
                                t(
                                    'ORDER_MANAGEMENT.OVERVIEW.TABLE_HEADER_MODEL'
                                )
                            }}
                        </th>
                        <th
                            mat-header-cell
                            mat-sort-header="date"
                            class="order-management__table-list-date-left"
                        >
                            {{
                                t('ORDER_MANAGEMENT.OVERVIEW.TABLE_HEADER_DATE')
                            }}
                        </th>
                        <th
                            mat-header-cell
                            mat-sort-header="configurationName"
                            class="order-management__table-list-name-left"
                        >
                            {{
                                t(
                                    'ORDER_MANAGEMENT.OVERVIEW.TABLE_HEADER_CONFIG'
                                )
                            }}
                        </th>
                        <th
                            mat-header-cell
                            class="order-management__table-list-menu-right"
                        ></th>
                        <th
                            mat-header-cell
                            class="order-management__table-list-button-right"
                        ></th>
                    </tr>
                </table>
                <mat-divider></mat-divider>
            </div>
            <div
                *ngFor="
                    let savedConfig of savedConfigurations;
                    let i = index;
                    trackBy: TRACK_BY_ID
                "
            >
                <span *ngIf="i + 1 === savedConfigurations.length" #theLastList>
                    <ng-container
                        *ngTemplateOutlet="
                            listItem;
                            context: {
                                savedConfig: savedConfig
                            }
                        "
                    ></ng-container>
                </span>
                <span *ngIf="i + 1 != savedConfigurations.length">
                    <ng-container
                        *ngTemplateOutlet="
                            listItem;
                            context: {
                                savedConfig: savedConfig
                            }
                        "
                    ></ng-container>
                </span>
            </div>
        </div>
    </div>
</ng-container>
<div class="order-management__sidebar">
    <mhp-sidebar-panel
        [items]="getSidebarItems$() | async"
        (itemSelect)="intentSidebarItemSelect($event)"
    ></mhp-sidebar-panel>
</div>

<ng-template #listItem let-savedConfig="savedConfig">
    <div class="order-management__table-list-entry" *transloco="let t">
        <img
            class="order-management__table-list-thumbnail"
            [src]="savedConfig.image"
        />

        <div mat-line class="order-management__table-list-model-title-left">
            <div mat-line>
                {{ savedConfig.model }}
            </div>
            <div mat-line *ngIf="">
                {{ savedConfig.model }}
            </div>
        </div>

        <div class="order-management__table-list-date-left">
            {{ savedConfig.date }}
        </div>
        <div class="order-management__table-list-name-left">
            {{ savedConfig.name }}
        </div>
        <mhp-ui-icon-button
            class="order-management__table-list-menu-right"
            [matMenuTriggerFor]="beforeMenu"
            svgIcon="mhp-ui:more"
        ></mhp-ui-icon-button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button
                mat-menu-item
                (click)="deleteConfiguration$(savedConfig.id)"
            >
                {{ t('COMMON.DELETE') }}
            </button>
        </mat-menu>
        <div class="order-management__table-list-button-right">
            <button
                mat-flat-button
                (click)="loadConfiguration(savedConfig.configurationURL)"
                class="mhp-ui-button"
            >
                {{ t('ORDER_MANAGEMENT.OVERVIEW.LOAD_CONFIG') }}
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
</ng-template>
