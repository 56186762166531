import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    combineLatest,
    distinctUntilChanged,
    of
} from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    switchMapTo,
    take,
    tap
} from 'rxjs/operators';

import {
    AnimationEvent,
    animate,
    animateChild,
    group,
    keyframes,
    query,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    Input,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { DealerInfoService, PricingType } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent, UiNavigationEntry } from '@mhp/ui-components';
import {
    ApplicationStateService,
    I18nService,
    L10nService,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import {
    ROUTE_ORDER_MANAGEMENT,
    ROUTE_ORDER_OVERVIEW
} from '../../app-route-names';
import { AmlBreakpoints } from '../../common/breakpoints/AmlBreakpoints';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../dealer';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { setPricingType } from '../../dealer/state/actions/dealer-state.actions';
import { setMenuActive } from '../../menu/state/actions/common-menu.actions';
import { RequestOne2oneSessionService } from '../../one2one/request-one2one-session/request-one2one-session.service';
import { One2oneSessionInfoService } from '../../one2one/session-info/one2one-session-info.service';
import { RegionService } from '../../settings/region-selector/region.service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { AmlCodeService } from '../aml-code/aml-code.service';
import { buildRegionChangeInterceptor } from '../common/region-change-interceptor';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { setFocusTargetNode } from '../state/actions/configuration.actions';
import { VisualizationMode } from '../state/local-configuration-state.interface';
import { StreamHandlerService } from '../stream/stream-handler.service';
import { VisualizationModeService } from '../visualization-mode/visualization-mode.service';
import { ClosableMenuInterface } from './closable-menu.interface';
import {
    CONFIGURATION_MENU_SIDEBAR_ENTRY_AML_CODE,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_CHANGE_REGION,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_LOCATE_DEALER,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_OM,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_TESTDRIVE,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_RESET_CONFIGURATION
} from './configuration-menu.constants';
import { MenuTocComponent } from './menu-toc/menu-toc.component';

@Component({
    selector: 'mhp-configuration-menu',
    templateUrl: './configuration-menu.component.html',
    styleUrls: ['./configuration-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [RequestOne2oneSessionService],
    animations: [
        trigger('menu', [
            // DESKTOP // LANDSCAPE ANIMATION
            state(
                'closing_landscape',
                style({
                    opacity: 0,
                    transform: 'translateX(-100%)'
                })
            ),
            state(
                'showing_landscape',
                style({
                    opacity: 1,
                    transform: 'translateX(-20px)'
                })
            ),
            transition(
                'void => showing_landscape',
                group([
                    style({
                        opacity: 0,
                        transform: 'translateX(-100%)'
                    }),
                    query(
                        '@menuContent',
                        animateChild({
                            // delay: '.25s'
                        })
                    ),
                    animate('1.5s cubic-bezier(0.33, 1, 0.4, 1.1)')
                ])
            ),
            transition(
                '* => closing_landscape',
                group([
                    query('@menuContent', animateChild()),
                    animate('.65s ease-in-out')
                ])
            ),
            // MOBILE // PORTRAIT ANIMATIONS
            state(
                'closing_portrait',
                style({
                    opacity: 0,
                    transform: 'translateY(-100%)'
                })
            ),
            state(
                'showing_portrait',
                style({
                    opacity: 1,
                    transform: 'translateY(0)'
                })
            ),
            transition(
                'void => showing_portrait',
                group([
                    style({
                        opacity: 0,
                        transform: 'translateY(-100%)'
                    }),
                    query(
                        '@menuContent',
                        animateChild({
                            // delay: '.25s'
                        })
                    ),
                    animate('1s ease-in-out')
                ])
            ),
            transition(
                '* => closing_portrait',
                group([animate('.65s ease-in-out')])
            )
        ]),
        // MENU-CONTENT ANIMATION
        trigger('menuContent', [
            // DESKTOP // LANDSCAPE ANIMATION
            state(
                'closing_landscape',
                style({
                    opacity: 0,
                    filter: 'blur(5px)',
                    transform: 'translateX(-100%)'
                })
            ),
            transition(
                'void => showing_landscape',
                animate(
                    '.1s ease-in-out',
                    keyframes([
                        style({
                            opacity: 0,
                            filter: 'blur(5px)',
                            transform: 'translateX(-10%)',
                            offset: 0
                        }),
                        style({
                            opacity: 0.5,
                            filter: 'blur(0)',
                            transform: 'translateX(0)',
                            offset: 0.8
                        }),
                        style({
                            opacity: 1,
                            offset: 1
                        })
                    ])
                )
            ),
            transition('* => closing_landscape', animate('.65s ease-in-out')),
            // MOBILE // PORTRAIT ANIMATION
            state(
                'closing_portrait',
                style({
                    opacity: 0,
                    filter: 'blur(5px)',
                    transform: 'translateY(-100%)'
                })
            ),
            transition('* => showing_portrait', [
                style({
                    opacity: 0,
                    filter: 'blur(5px)',
                    transform: 'translateY(-100%)'
                }),
                animate(
                    '1s ease-in-out',
                    style({
                        opacity: 1,
                        filter: 'blur(0)',
                        transform: 'translateY(0)'
                    })
                )
            ]),
            transition('* => closing_portrait', animate('.65s ease-in-out'))
        ])
    ]
})
export class ConfigurationMenuComponent
    extends UiBaseComponent
    implements ClosableMenuInterface
{
    private readonly targetAnimationStateSubject = new BehaviorSubject<
        'showing' | 'closing'
    >('showing');

    private readonly closeAnimationEndSubject = new Subject<undefined>();

    private readonly sidebarItems = <UiNavigationEntry<string>[]>[
        {
            id: CONFIGURATION_MENU_SIDEBAR_ENTRY_RESET_CONFIGURATION,
            label: translate('MENU_CUSTOMER.CTA_RESET_CONFIGURATION'),
            iconId: 'mhp-ui:cam-reset'
        },
        environment.appConfig.featureToggles.one2oneBuyingExperience
            ? {
                  id: CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE,
                  label: translate('MENU_CUSTOMER.CTA_ONE2ONE'),
                  iconId: 'mhp-ui:one2one'
              }
            : undefined,
        {
            id: CONFIGURATION_MENU_SIDEBAR_ENTRY_AML_CODE,
            iconId: 'mhp-ui:config_code',
            label: translate('DERIVATIVE_SELECTION.CTA_LOAD_CONFIGURATION_CODE')
        }
        // Insert VIN icon here as soon as feature is implemented, see https://mhpimmersive.atlassian.net/browse/AMR-146?atlOrigin=eyJpIjoiMWQ3NTc4YTY0MzVjNDdhN2I5ZmEwMzlhMWIyNGIwNjEiLCJwIjoiaiJ9

        // {
        //     id: CONFIGURATION_MENU_SIDEBAR_ENTRY_SHARE,
        //     label: translate('ICONS.SHARE_ALT'),
        //     iconId: 'mhp-ui:share'
        // },
    ];

    readonly ACTION_RESET_CONFIGURATION: UiNavigationEntry<string> = {
        id: CONFIGURATION_MENU_SIDEBAR_ENTRY_RESET_CONFIGURATION,
        label: translate('MENU_CUSTOMER.CTA_RESET_CONFIGURATION')
    };

    readonly ACTION_ONCFIGURATION_CODE: UiNavigationEntry<string> = {
        id: CONFIGURATION_MENU_SIDEBAR_ENTRY_AML_CODE,
        label: translate('MENU_CUSTOMER.CTA_CONFIGURATION')
    };

    readonly ACTION_REQUEST_TESTDRIVE: UiNavigationEntry<string> = {
        id: CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_TESTDRIVE,
        label: translate('SUMMARY.SUBMIT_CUSTOMER.BOOK_TESTDRIVE')
    };

    readonly ACTION_AML_HOME: UiNavigationEntry<string> = {
        id: 'AML_HOME',
        label: translate('MENU_CUSTOMER.CTA_WEBSITE')
    };

    readonly ACTION_CHANGE_REGION: UiNavigationEntry<string> = {
        id: CONFIGURATION_MENU_SIDEBAR_ENTRY_CHANGE_REGION,
        label: translate('ICONS.REGION_ALT'),
        iconId: 'mhp-ui:language'
    };

    readonly ROUTE_ORDER_MANAGEMENT: UiNavigationEntry<string> = {
        id: CONFIGURATION_MENU_SIDEBAR_ENTRY_OM,
        label: translate('ICON.')
    };

    readonly isBackButtonDisplayedSubject = new BehaviorSubject<boolean>(false);

    @ViewChild('menuTocElement', { static: false, read: MenuTocComponent })
    menuTocElement: MenuTocComponent;

    @Input()
    alternativeMenuContentsTemplate: TemplateRef<unknown>;

    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly regionService: RegionService,
        private readonly visualizationModeService: VisualizationModeService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly pricingService: PricingService,
        private readonly amlCodeService: AmlCodeService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly requestOne2oneSessionService: RequestOne2oneSessionService,
        private readonly one2oneSessionInfoService: One2oneSessionInfoService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly router: Router,
        private readonly streamHandlerService: StreamHandlerService,
        public readonly dealerInfoService: DealerInfoService
    ) {
        super();
    }

    @HostListener('document:keydown.escape', ['$event'])
    intentClose() {
        this.applicationStateService.dispatch(
            setMenuActive({
                menuActive: false
            })
        );
    }

    intentGoBack(): void {
        this.setBackButtonState(false);
    }

    @MemoizeObservable()
    getActions$(): Observable<UiNavigationEntry<string>[]> {
        return combineLatest([
            this.getAllSidebarItemsAdjusted$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(([allSidebarItems, breakpointState]) =>
                allSidebarItems
                    /** On mobile portrait breakpoint, show a maximum no of 6 icons */
                    .filter((item, index) =>
                        breakpointState.matches
                            ? index <
                              CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT
                            : true
                    )
            )
        );
    }

    @MemoizeObservable()
    getSidebarItemsAsLinks$(): Observable<
        UiNavigationEntry<string>[] | undefined
    > {
        return combineLatest([
            this.getAllSidebarItemsAdjusted$(),
            this.getActions$()
        ]).pipe(
            map(([allSidebarItems, itemsInSidebar]) =>
                allSidebarItems.filter(
                    (item) =>
                        !itemsInSidebar.find(
                            (itemInSidebar) => itemInSidebar.id === item.id
                        )
                )
            )
        );
    }

    @MemoizeObservable()
    getAvailablePricingTypes$(): Observable<PricingType[]> {
        return this.pricingService.getAvailablePricingTypes$();
    }

    @MemoizeObservable()
    getAnimationState$(): Observable<
        | 'showing_landscape'
        | 'closing_landscape'
        | 'showing_portrait'
        | 'closing_portrait'
    > {
        return combineLatest([
            this.targetAnimationStateSubject,
            this.breakpointObserver
                .observe([AmlBreakpoints.Portrait, AmlBreakpoints.Handset])
                .pipe(map((breakpointState) => breakpointState.matches))
        ]).pipe(
            map(
                ([targetAnimationState, isPortrait]) =>
                    `${targetAnimationState}_${
                        isPortrait ? 'portrait' : 'landscape'
                    }` as
                        | 'showing_landscape'
                        | 'closing_landscape'
                        | 'showing_portrait'
                        | 'closing_portrait'
            ),
            distinctUntilChanged()
        );
    }

    intentActionSelect(itemId: string) {
        if (
            this.dealerHooksService.handleSidebarItemSelect(
                itemId,
                'CONFIGURATION-MENU'
            )
        ) {
            return;
        }

        switch (itemId) {
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_CHANGE_REGION:
                gtmGA4Track('select_region_language_click');
                this.regionService
                    .requestAndApplyRegionAndLanguageSelection$(
                        {
                            allowClose: true
                        },
                        buildRegionChangeInterceptor(
                            this.productConfigurationSessionService
                        )
                    )
                    .pipe(this.takeUntilDestroy())
                    .subscribe();
                break;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_AML_CODE:
                gtmGA4Track('load_configuration_code_click');
                this.productConfigurationSessionService
                    .intentDiscardConfiguration$(
                        of(undefined).pipe(
                            // when continuing here, we have to mark ourselves as dirty for the CD to be run to activate the aml-code dialog
                            tap(() => this.changeDetectorRef.markForCheck()),
                            switchMapTo(
                                this.amlCodeService.openLoadAmlCodeDialog$(
                                    this.viewContainerRef
                                )
                            )
                        )
                    )
                    .pipe(this.takeUntilDestroy())
                    .subscribe(() => {
                        this.intentClose();
                    });
                break;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_RESET_CONFIGURATION:
                gtmGA4Track('reset_configuration_code_click');
                this.productConfigurationSessionService
                    .resetConfiguration$()
                    .pipe(this.takeUntilDestroy())
                    .subscribe();
                break;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_LOCATE_DEALER:
                gtmGA4Track('find_dealer_click');
                window.open('https://www.astonmartin.com/dealers', '_blank');
                break;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_TESTDRIVE:
                gtmGA4Track('book_test_drive_click');
                window.open('https://www.astonmartin.com/enquiry', '_blank');
                break;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE:
                gtmGA4Track('request_one_to_one_session_click');
                this.configurationSessionInfoService
                    .getActiveConfigurationSessionInfo$()
                    .pipe(
                        take(1),
                        switchMap((activeSessionInfo) => {
                            if (!activeSessionInfo) {
                                return EMPTY;
                            }
                            return this.requestOne2oneSessionService.intentRequestOne2OneSession$(
                                {
                                    modelIdOfInterest:
                                        activeSessionInfo.modelId,
                                    productIdOfInterest:
                                        activeSessionInfo.productId,
                                    hideProductOfInterestSelection: true
                                }
                            );
                        }),
                        this.takeUntilDestroy()
                    )
                    .subscribe();
                break;
            case 'AML_HOME':
                gtmGA4Track('homepage_click');
                window.open('https://www.astonmartin.com', '_blank');
                break;
            default:
                break;
        }
    }

    intentSelectCategory(id: string) {
        this.applicationStateService.dispatch(
            setFocusTargetNode({
                id
            })
        );

        this.intentClose();
    }

    @MemoizeObservable()
    isStreamTargetVisualizationMode$(): Observable<boolean> {
        return this.visualizationModeService
            .getTargetVisualizationMode$()
            .pipe(map((visMode) => visMode === VisualizationMode.STREAM));
    }

    @MemoizeObservable()
    isStreamToggleDisabled$() {
        return combineLatest([
            this.one2oneSessionInfoService.isOne2OneHostSessionActive$(),
            this.isStreamTargetVisualizationMode$()
        ]).pipe(
            map(
                ([one2oneHostSessionActive, isStreamTargetVisualisationMode]) =>
                    one2oneHostSessionActive && isStreamTargetVisualisationMode
            )
        );
    }

    /**
     * Stream-settings is currently only visible for the UK market.
     */
    @MemoizeObservable()
    isPublicStreamSettingVisible$() {
        return this.visualizationModeService
            .isStreamVisualizationModeAvailable$()
            .pipe(
                switchMap((isStreamAvailable) => {
                    if (!isStreamAvailable) {
                        return of(false);
                    }

                    if (environment.appConfig.dealer.dealerBuild) {
                        // ignore the quota-check for dealers
                        return of(isStreamAvailable);
                    }

                    // check whether there is stream-quota left
                    return this.streamHandlerService.checkQuota$().pipe(
                        // in case we're not able to check the quota, assume no stream is available.
                        catchError(() => of(false))
                    );
                })
            );
    }

    intentToggleTargetVizualisationMode(event: MatSlideToggleChange) {
        const targetMode = event.checked
            ? VisualizationMode.STREAM
            : VisualizationMode.BASIC;

        this.visualizationModeService.setTargetVisualizationMode(
            targetMode,
            true
        );

        gtmGA4Track('immersive_experience_click', {
            immersive_experience_status:
                targetMode === 'STREAM' ? 'active' : 'inactive'
        });
        this.intentClose();
    }

    intentChangePricingType(pricingType: PricingType) {
        this.applicationStateService.dispatch(
            setPricingType({
                pricingType,
                settingSource: 'USER'
            })
        );
    }

    @MemoizeObservable()
    getPricingType$() {
        return this.pricingService.getActivePricingType$();
    }

    intentDealerLogout() {
        this.dealerHooksService.intentLogout$().subscribe();
    }

    prepareClose$() {
        this.targetAnimationStateSubject.next('closing');
        return this.closeAnimationEndSubject.asObservable();
    }

    @MemoizeObservable()
    private getAllSidebarItemsAdjusted$() {
        return combineLatest([
            this.i18nService.getActiveLang$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(([, breakpointState]) =>
                this.dealerHooksService.adjustSidebarItems(
                    this.sidebarItems.filter(
                        (item): item is UiNavigationEntry<string> => !!item
                    ),
                    'CONFIGURATION-MENU'
                )
            ),
            lazyShareReplay()
        );
    }

    openOMOrderOverview() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_OVERVIEW}`
        );
    }

    @MemoizeObservable()
    getActiveCountryName$() {
        return this.l10nService
            .getActiveCountry$()
            .pipe(
                switchMap((isoCountry) =>
                    this.i18nService.selectTranslate$(
                        `COMMON.REGION.${isoCountry}`
                    )
                )
            );
    }

    @MemoizeObservable()
    isFullScreenLayout$() {
        return this.breakpointObserver
            .observe([
                Breakpoints.Handset,
                Breakpoints.TabletPortrait,
                Breakpoints.WebPortrait
            ])
            .pipe(map((currentState) => currentState.matches));
    }

    onMenuContentAnimationDone(animationEvent?: AnimationEvent) {
        if (!animationEvent?.toState.startsWith('closing_')) {
            return;
        }
        this.closeAnimationEndSubject.next(undefined);
    }

    setBackButtonState(display: boolean): void {
        this.isBackButtonDisplayedSubject.next(display);

        // used in order to reset the root items selection
        if (!display) {
            setTimeout(() => this.menuTocElement.intentClearActiveItem(), 50);
        }
    }
}
