import { filter, takeUntil } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    SkipSelf,
    ViewContainerRef
} from '@angular/core';
import { UiBaseComponent, UiMatDialogService } from '@mhp/ui-components';
import {
    FeatureAvailabilityService,
    gtmGA4Track
} from '@mhp/ui-shared-services';
import { UntilDestroy } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { BACKDROP_CLASS_BLURRY } from '../../../common/dialog/dialog.constants';
import { VisualizationMode } from '../../state';
import { VisualizationModeService } from '../../visualization-mode/visualization-mode.service';
import { CinematicShareDialogComponent } from '../cinematic-share-dialog/cinematic-share-dialog.component';

@UntilDestroy()
@Component({
    selector: 'mhp-cinematic-dialog',
    templateUrl: './cinematic-dialog.component.html',
    styleUrls: ['./cinematic-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CinematicDialogComponent extends UiBaseComponent {
    readonly isDownloadCinematicSupported =
        environment.appConfig.featureToggles.downloadCinematics;

    constructor(
        @SkipSelf() private readonly viewContainerRef: ViewContainerRef,
        @SkipSelf()
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        public readonly matDialogService: UiMatDialogService,
        public readonly featureAvailabilityService: FeatureAvailabilityService,
        private readonly visualizationModeService: VisualizationModeService
    ) {
        super();
    }

    intentShare() {
        gtmGA4Track('cinematic_share_click');

        this.matDialogService
            .open$(CinematicShareDialogComponent, {
                viewContainerRef: this.viewContainerRef,
                componentFactoryResolver: this.componentFactoryResolver,
                backdropClass: BACKDROP_CLASS_BLURRY,
                maxWidth: 500
            })
            .pipe(
                takeUntil(
                    this.visualizationModeService
                        .getActiveVisualizationMode$()
                        .pipe(
                            filter((mode) => mode !== VisualizationMode.STREAM)
                        )
                )
            )
            .subscribe();
    }
}
