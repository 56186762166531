export function slugifyString(inString: string) {
    if (inString) {
        inString = inString.replace(/^\s+|\s+$/g, ''); // trim
        inString = inString.toLowerCase();

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        const to = 'aaaaeeeeiiiioooouuuunc------';
        for (let i = 0, l = from.length; i < l; i += 1) {
            inString = inString.replace(
                new RegExp(from.charAt(i), 'g'),
                to.charAt(i)
            );
        }

        inString = inString
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-') // collapse dashes
            .split('-')
            .join('_')
            .toUpperCase();
    }
    return inString;
}
