/**
 * Defines countries for which RRP / MSRP prices are available.
 */
export const RRP_COUNTRIES = [
    'JP',
    'CN',
    'BE',
    'FR',
    'MC',
    'DE',
    'IT',
    'LU',
    'PL',
    'ES',
    'CA',
    'US',
    'GB',
    'AU',
    'LV',
    'LT',
    'JE'
];
export const RRP_COUNTRIES_SET: Set<string> = new Set<string>(RRP_COUNTRIES);
