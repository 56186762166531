import { COUNTRIES } from './countries';
import { COUNTRIES_TO_REMAP } from './countries-to-remap';
import { COUNTRY_TO_CURRENCY } from './currencies';
import { LOCALES } from './locales';
import { TERRITORY_MAP } from './territories';

export function getCountryISOCodeByCountryName(
    countryName: string
): string | undefined {
    return Object.keys(COUNTRIES).find((key) => {
        let result = false;
        for (const country of COUNTRIES[key]) {
            result = countryName.toLowerCase() === country.toLowerCase();
            if (result) {
                break;
            }
        }
        return result;
    });
}

export function getCountryNameByCountryISOCode(countryISOCode: string): string {
    return COUNTRIES[countryISOCode][0];
}

export function getCountryISOCodeListByTerritoryCode(
    territoryCode: string
): string[] {
    return TERRITORY_MAP[territoryCode];
}

export function getCountryISOCodeListByTerritoryCodeListString(
    territoryCodeListString: string
): string[] {
    const territoryCodeList = territoryCodeListString.match(/.{2}/g) || [];
    const isoCodeList: string[] = [];
    for (const territoryCode of territoryCodeList) {
        isoCodeList.push(
            ...getCountryISOCodeListByTerritoryCode(territoryCode)
        );
    }
    return isoCodeList;
}

export function getTerritoryCodeByCountryISOCode(
    countryISOCode: string
): string | undefined {
    return Object.keys(TERRITORY_MAP).find((key) =>
        TERRITORY_MAP[key].includes(countryISOCode)
    );
}

export function getLocaleByCountryISOCode(
    countryISOCode: string
): string | undefined {
    return LOCALES[countryISOCode];
}

export function getListOfTerritories(): string[] {
    return Object.keys(TERRITORY_MAP);
}

export function getListOfCountryISOCodes(): string[] {
    return Object.keys(COUNTRIES);
}

export function getSetOfCountryISOCodes(): Set<string> {
    return new Set(Object.keys(COUNTRIES));
}

export function getListOfCountryNames(): string[] {
    return Object.keys(COUNTRIES).map<string>((key) => COUNTRIES[key][0]);
}

export function mapISOCodesToCountryCodes(country: string): string {
    let remappedCountryCode = country;
    for (const { countryCodesToMap, target } of COUNTRIES_TO_REMAP) {
        if (countryCodesToMap.includes(country)) {
            remappedCountryCode = target;
            break;
        }
    }

    return remappedCountryCode;
}

/**
 * Returns all countries that needs to get remapped
 */
export function getCountriesToRemap(): string[] {
    return COUNTRIES_TO_REMAP.map((countries) => countries.countryCodesToMap)
        .join('%')
        .split('%');
}

/**
 * Return the ISO 4217 currency code for a given country.
 * @param countryISOCode The ISO 3166-1 Alpha-2 country code
 */
export function getCurrencyISOCodeByCountryISOCode(
    countryISOCode: string
): string {
    return COUNTRY_TO_CURRENCY[countryISOCode];
}

/**
 * Returns a mapping from a countries default locale to its ISO 4217 currency code.
 */
export function getMapOfLocaleToCurrencyISOCodes(): { [key: string]: string } {
    return Object.freeze(
        Object.entries(COUNTRY_TO_CURRENCY).reduce(
            (mapping: Record<string, string>, [country, currency]) => {
                const matchingLocale = getLocaleByCountryISOCode(country);
                if (!matchingLocale) {
                    throw new Error(
                        `Could not find matching locale for country ${country}`
                    );
                }
                mapping[matchingLocale] = currency;
                return mapping;
            },
            {}
        )
    );
}
