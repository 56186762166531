import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiIconButtonModule,
    UiIconsModule,
    UiInputModule,
    UiMatDialogModule,
    UiMenuModule,
    UiProgressSpinnerModule,
    UiSelectModule,
    UiSlideToggleModule,
    UiTabsModule
} from '@mhp/ui-components';
import { I18nModule, L10nModule } from '@mhp/ui-shared-services';

import { FooterModule } from '../common/footer/footer.module';
import { LoadingIndicatorModule } from '../common/loading-indicator/loading-indicator.module';
import { SearchModule } from '../common/search/search.module';
import { SpinnerModule } from '../common/spinner/spinner.module';
import { DealerHelperModule } from '../dealer/dealer-helper/dealer-helper.module';
import { DialogModule } from '../dialog/dialog.module';
import { HeaderBarModule } from '../header-bar/header-bar.module';
import { SidebarPanelModule } from '../menu/sidebar-panel/sidebar-panel.module';
import { FormFieldDialogComponent } from './form-field-dialog/form-field-dialog.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { CopyConfigurationDialogComponent } from './order-edit/copy-configuration-dialog/copy-configuration-dialog.component';
import { OrderConfigurationFormFieldComponent } from './order-edit/order-configuration-form/order-configuration-form-field/order-configuration-form-field.component';
import { OrderConfigurationFormComponent } from './order-edit/order-configuration-form/order-configuration-form.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderManagementRoutingModule } from './order-management-routing.module';
import { OrderManagmentComponent } from './order-managment.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { PricingDialogComponent } from './pricing-dialog/pricing-dialog.component';
import { PricingSectionComponent } from './pricing-section/pricing-section.component';
import { SaveConfigurationDialogComponent } from './save-configuration-dialog/save-configuration-dialog.component';
import { SectionTitleComponent } from './section-title/section-title.component';

@NgModule({
    declarations: [
        OrderManagmentComponent,
        OrderOverviewComponent,
        OrderDetailComponent,
        OrderEditComponent,
        PricingDialogComponent,
        OrderConfigurationFormComponent,
        OrderConfigurationFormFieldComponent,
        PricingSectionComponent,
        SectionTitleComponent,
        SaveConfigurationDialogComponent,
        FormFieldDialogComponent,
        CopyConfigurationDialogComponent
    ],
    imports: [
        CommonModule,
        DialogModule,
        FooterModule,
        HeaderBarModule,
        I18nModule,
        L10nModule,
        MatDividerModule,
        UiMenuModule,
        MatSortModule,
        OrderManagementRoutingModule,
        UiProgressSpinnerModule,
        ReactiveFormsModule,
        SearchModule,
        SpinnerModule,
        SidebarPanelModule,
        UiIconButtonModule,
        UiButtonModule,
        UiCheckboxModule,
        UiInputModule,
        UiMatDialogModule,
        UiSelectModule,
        UiSlideToggleModule,
        UiTabsModule,
        LoadingIndicatorModule,
        UiIconsModule,
        DealerHelperModule
    ],
    providers: [DatePipe],
    exports: [OrderManagmentComponent, OrderOverviewComponent]
})
export class OrderManagementModule {}
