import { isNumber } from 'lodash-es';
import { delay, filter, map } from 'rxjs';

import { animate, style, transition, trigger } from '@angular/animations';
import { CdkScrollable } from '@angular/cdk/scrolling';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    input
} from '@angular/core';
import { CategoryItem } from '@mhp/aml-ui-shared-services/configuration/categories';
import {
    EASE_OUT_EXPO,
    TRACK_BY_ID,
    UiBaseComponent
} from '@mhp/ui-components';

const SMOOTH_EXPAND_TIMING = `250ms ${EASE_OUT_EXPO}`;

@Component({
    selector: 'mhp-expandable-category-bar',
    templateUrl: './expandable-category-bar.component.html',
    styleUrls: ['./expandable-category-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('smoothExpand', [
            transition('* => void', [
                style({ width: '*' }),
                animate(SMOOTH_EXPAND_TIMING, style({ width: 0 }))
            ]),
            transition('void => *', [
                style({ width: 0 }),
                animate(SMOOTH_EXPAND_TIMING, style({ width: '*' }))
            ])
        ])
    ]
})
export class ExpandableCategoryBarComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @Input()
    categoryItems: CategoryItem[];

    @Input()
    canSelectNext: boolean;

    @Input()
    canSelectPrev: boolean;

    @Input()
    scrollPosition?: number;

    // should the scroll-into-view behavior be disabled?
    @Input()
    disableScrollIntoView?: boolean;

    @Input()
    noItemsTemplate: TemplateRef<unknown>;

    @Input()
    categoryLabelExtensionTemplate?: TemplateRef<unknown>;

    optionSelectionDisabled = input<boolean>(false);

    @Output()
    readonly selectCategory = new EventEmitter<CategoryItem>();

    @Output()
    readonly selectNext = new EventEmitter<void>();

    @Output()
    readonly selectPrev = new EventEmitter<void>();

    @Output()
    readonly scrollPositionChange = new EventEmitter<number>();

    /**
     * Showing additional information about a certain category-item is requested.
     */
    @Output()
    readonly showInfo = new EventEmitter<CategoryItem>();

    @ViewChild(CdkScrollable)
    set categoryBarScrollable(scrollable: CdkScrollable) {
        this._categoryBarScrollable = scrollable;
    }

    get categoryBarScrollable() {
        return this._categoryBarScrollable;
    }

    private _categoryBarScrollable: CdkScrollable;

    // required to not run animations on initial load
    initialized = false;

    readonly trackById = TRACK_BY_ID;
    readonly categoryItemComparator = (
        item1: CategoryItem,
        item2: CategoryItem
    ) => item1?.id === item2?.id;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        super();

        this.completeOnDestroy(
            this.selectCategory,
            this.selectNext,
            this.selectPrev,
            this.scrollPositionChange,
            this.showInfo
        );
    }

    ngAfterViewInit() {
        this.initialized = true;

        this.initScrollPositionRestore();
        this.initScrollIntoView();
    }

    onCategorySelected(category: CategoryItem) {
        this.selectCategory.emit(category);
    }

    intentGoToPrev() {
        this.selectPrev.emit();
    }

    intentGoToNext() {
        this.selectNext.emit();
    }

    intentShowInfo(categoryItem: CategoryItem) {
        this.showInfo.emit(categoryItem);
    }

    getSelectedChild(children: CategoryItem[] | undefined) {
        return children?.find((child) => child.selected);
    }

    /**
     * Test if the given CategoryItem has at least one child with a description set
     * @param categoryItem
     */
    childHasDescription(categoryItem: CategoryItem): boolean {
        return !!categoryItem.children?.some((item) => !!item.description);
    }

    private initScrollPositionRestore() {
        if (isNumber(this.scrollPosition)) {
            this.categoryBarScrollable.scrollTo({
                behavior: 'auto',
                left: this.scrollPosition
            });
        }

        this._categoryBarScrollable
            .elementScrolled()
            .pipe(this.takeUntilDestroy())
            .subscribe(() => {
                this.scrollPositionChange.next(
                    this.categoryBarScrollable.measureScrollOffset('left')
                );
            });
    }

    private initScrollIntoView() {
        this.observeProperty<ExpandableCategoryBarComponent, unknown>(
            'categoryItems'
        )
            .pipe(
                filter(() => !this.disableScrollIntoView),
                // wait for item-rendering
                delay(500),
                map(() => {
                    const activeElementToBeFocussed =
                        this.elementRef.nativeElement.querySelector(
                            '.expandable-category-bar__sub-entry--active'
                        ) ||
                        this.elementRef.nativeElement.querySelector(
                            '.expandable-category-bar__toplevel-entry--active'
                        );

                    /* Determine if this element is the first or last "flat" one in list to fix an issue, where focussing a dropdown-style element,
                     * which cannot be "active" as such, is not possible and thus could stay out-of-view.
                     * See https://dev.azure.com/AstonMartinLagonda/Digital/_workitems/edit/65101
                     */
                    const flatItems =
                        this.elementRef.nativeElement.querySelectorAll(
                            '.expandable-category-bar__toplevel-entry--flat'
                        );
                    const activeElementIsFirstFlatElement =
                        flatItems.item(0) === activeElementToBeFocussed;
                    const activeElementIsLastFlatElement =
                        flatItems.item(flatItems.length - 1) ===
                        activeElementToBeFocussed;

                    return {
                        activeElement: activeElementToBeFocussed,
                        isFirstFlatElement: activeElementIsFirstFlatElement,
                        isLastFlatElement: activeElementIsLastFlatElement
                    };
                })
            )
            .subscribe(
                ({ activeElement, isFirstFlatElement, isLastFlatElement }) => {
                    if (activeElement) {
                        let horizontalScrollTarget: ScrollLogicalPosition =
                            'nearest';
                        if (isFirstFlatElement) {
                            horizontalScrollTarget = 'end';
                        } else if (isLastFlatElement) {
                            horizontalScrollTarget = 'start';
                        }
                        activeElement.scrollIntoView({
                            block: 'nearest',
                            inline: horizontalScrollTarget,
                            behavior: 'smooth'
                        });
                    }
                }
            );
    }
}
